var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"p-5"},[_c('header',{staticClass:"text-header-blue text-xl font-semibold mb-3"},[_vm._v("Befattningshavare")]),_c('header',{staticClass:"text-header-blue text-lg font-semibold mb-3"},[_vm._v("Privatperson")]),_c('div',{staticClass:"flex flex-row"},[_c('el-table',{attrs:{"data":_vm.executivePerson}},[_c('el-table-column',{attrs:{"label":"Personnummer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{model:{value:(row.civicNumber),callback:function ($$v) {_vm.$set(row, "civicNumber", $$v)},expression:"row.civicNumber"}})]}}])}),_c('el-table-column',{attrs:{"label":"Förnamn"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{model:{value:(row.firstName),callback:function ($$v) {_vm.$set(row, "firstName", $$v)},expression:"row.firstName"}})]}}])}),_c('el-table-column',{attrs:{"label":"Efternamn"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{model:{value:(row.lastName),callback:function ($$v) {_vm.$set(row, "lastName", $$v)},expression:"row.lastName"}})]}}])}),_c('el-table-column',{attrs:{"label":"Roll"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-select',{staticClass:"w-full",attrs:{"title":row.role},model:{value:(row.role),callback:function ($$v) {_vm.$set(row, "role", $$v)},expression:"row.role"}},_vm._l((_vm.selectExecutiveRole),function(role){return _c('el-option',{key:role.id,attrs:{"label":role.name,"value":role.name,"title":role.name}})}),1)]}}])}),_c('el-table-column',{attrs:{"label":"Ägare?"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('RadioButtonList',{staticClass:"mt-2",attrs:{"entries":_vm.yesNoExecutive,"horizontal":true,"name":"?"},model:{value:(row.owner),callback:function ($$v) {_vm.$set(row, "owner", $$v)},expression:"row.owner"}})]}}])}),_c('el-table-column',{attrs:{"label":"Antal A-aktier"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.owner === 0)?_c('el-input',{model:{value:(row.numAShares),callback:function ($$v) {_vm.$set(row, "numAShares", $$v)},expression:"row.numAShares"}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"Antal B-aktier"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.owner === 0)?_c('el-input',{model:{value:(row.numBShares),callback:function ($$v) {_vm.$set(row, "numBShares", $$v)},expression:"row.numBShares"}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"Antal röster"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.owner === 0)?_c('el-input',{model:{value:(row.numVotes),callback:function ($$v) {_vm.$set(row, "numVotes", $$v)},expression:"row.numVotes"}}):_vm._e()]}}])}),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var $index = ref.$index;
var store = ref.store;
var row = ref.row;
return [(store.states.data.length !== 1)?[_c('el-button',{attrs:{"plain":"","size":"mini"},on:{"click":function($event){return _vm.deleteRowExecutivePerson(row)}}},[_c('i',{staticClass:"fa-solid fa-minus"})])]:_vm._e(),($index === store.states.data.length - 1)?[_c('el-button',{attrs:{"plain":"","size":"mini"},on:{"click":_vm.addRowExecutivePerson}},[_c('i',{staticClass:"fa-solid fa-plus"})])]:_vm._e()]}}])})],1)],1)]),_c('div',{staticClass:"p-5"},[_c('header',{staticClass:"text-header-blue text-lg font-semibold mb-3"},[_vm._v("Juridisk person (företag och organisationer)")]),_c('div',{staticClass:"flex flex-row"},[_c('el-table',{attrs:{"data":_vm.executiveCompany}},[_c('el-table-column',{attrs:{"label":"Organisationsnummer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{model:{value:(row.orgNumber),callback:function ($$v) {_vm.$set(row, "orgNumber", $$v)},expression:"row.orgNumber"}})]}}])}),_c('el-table-column',{attrs:{"label":"Företagsnamn"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{model:{value:(row.companyName),callback:function ($$v) {_vm.$set(row, "companyName", $$v)},expression:"row.companyName"}})]}}])}),_c('el-table-column',{attrs:{"label":"Ställföreträdare"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{model:{value:(row.representative),callback:function ($$v) {_vm.$set(row, "representative", $$v)},expression:"row.representative"}})]}}])}),_c('el-table-column',{attrs:{"label":"Antal A-aktier"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.owner === 0)?_c('el-input',{model:{value:(row.numAShares),callback:function ($$v) {_vm.$set(row, "numAShares", $$v)},expression:"row.numAShares"}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"Antal B-aktier"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.owner === 0)?_c('el-input',{model:{value:(row.numBShares),callback:function ($$v) {_vm.$set(row, "numBShares", $$v)},expression:"row.numBShares"}}):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"Antal röster"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.owner === 0)?_c('el-input',{model:{value:(row.numVotes),callback:function ($$v) {_vm.$set(row, "numVotes", $$v)},expression:"row.numVotes"}}):_vm._e()]}}])}),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var $index = ref.$index;
var store = ref.store;
var row = ref.row;
return [(store.states.data.length !== 1)?[_c('el-button',{attrs:{"plain":"","size":"mini"},on:{"click":function($event){return _vm.deleteRowExecutiveCompany(row)}}},[_c('i',{staticClass:"fa-solid fa-minus"})])]:_vm._e(),($index === store.states.data.length - 1)?[_c('el-button',{attrs:{"plain":"","size":"mini"},on:{"click":_vm.addRowExecutiveCompany}},[_c('i',{staticClass:"fa-solid fa-plus"})])]:_vm._e()]}}])})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }