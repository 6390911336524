<template>
    <div>
        <div class="p-5">
            <header class="text-header-blue text-xl font-semibold mb-3">Befattningshavare</header>
            <header class="text-header-blue text-lg font-semibold mb-3">Privatperson</header>
            <div class="flex flex-row">
                <el-table :data="executivePerson">
                    <el-table-column label="Personnummer">
                        <template slot-scope="{ row }">
                            <el-input v-model="row.civicNumber" />
                        </template>
                    </el-table-column>
                    <el-table-column label="Förnamn">
                        <template slot-scope="{ row }">
                            <el-input v-model="row.firstName" />
                        </template>
                    </el-table-column>
                    <el-table-column label="Efternamn">
                        <template slot-scope="{ row }">
                            <el-input v-model="row.lastName" />
                        </template>
                    </el-table-column>
                    <el-table-column label="Roll">
                        <template slot-scope="{ row }">
                            <el-select class="w-full" v-model="row.role" :title="row.role">
                                <el-option v-for="role in selectExecutiveRole" :label="role.name" :value="role.name" :key="role.id" :title="role.name" />
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column label="Ägare?">
                        <template slot-scope="{ row }">
                            <RadioButtonList :entries="yesNoExecutive" v-model="row.owner" :horizontal="true" class="mt-2" name="?" />
                        </template>
                    </el-table-column>
                    <el-table-column label="Antal A-aktier">
                        <template slot-scope="{ row }">
                            <el-input v-model="row.numAShares" v-if="row.owner === 0" />
                        </template>
                    </el-table-column>
                    <el-table-column label="Antal B-aktier">
                        <template slot-scope="{ row }">
                            <el-input v-model="row.numBShares" v-if="row.owner === 0" />
                        </template>
                    </el-table-column>
                    <el-table-column label="Antal röster">
                        <template slot-scope="{ row }">
                            <el-input v-model="row.numVotes" v-if="row.owner === 0" />
                        </template>
                    </el-table-column>
                    <el-table-column>
                        <template slot-scope="{ $index, store, row }">
                            <template v-if="store.states.data.length !== 1">
                                <el-button plain size="mini" @click="deleteRowExecutivePerson(row)">
                                    <i class="fa-solid fa-minus" />
                                </el-button>
                            </template>
                            <template v-if="$index === store.states.data.length - 1">
                                <el-button plain size="mini" @click="addRowExecutivePerson">
                                    <i class="fa-solid fa-plus" />
                                </el-button>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="p-5">
            <header class="text-header-blue text-lg font-semibold mb-3">Juridisk person (företag och organisationer)</header>
            <div class="flex flex-row">
                <el-table :data="executiveCompany">
                    <el-table-column label="Organisationsnummer">
                        <template slot-scope="{ row }">
                            <el-input v-model="row.orgNumber" />
                        </template>
                    </el-table-column>
                    <el-table-column label="Företagsnamn">
                        <template slot-scope="{ row }">
                            <el-input v-model="row.companyName" />
                        </template>
                    </el-table-column>
                    <el-table-column label="Ställföreträdare">
                        <template slot-scope="{ row }">
                            <el-input v-model="row.representative" />
                        </template>
                    </el-table-column>
                    <el-table-column label="Antal A-aktier">
                        <template slot-scope="{ row }">
                            <el-input v-model="row.numAShares" v-if="row.owner === 0" />
                        </template>
                    </el-table-column>
                    <el-table-column label="Antal B-aktier">
                        <template slot-scope="{ row }">
                            <el-input v-model="row.numBShares" v-if="row.owner === 0" />
                        </template>
                    </el-table-column>
                    <el-table-column label="Antal röster">
                        <template slot-scope="{ row }">
                            <el-input v-model="row.numVotes" v-if="row.owner === 0" />
                        </template>
                    </el-table-column>
                    <el-table-column>
                        <template slot-scope="{ $index, store, row }">
                            <template v-if="store.states.data.length !== 1">
                                <el-button plain size="mini" @click="deleteRowExecutiveCompany(row)">
                                    <i class="fa-solid fa-minus" />
                                </el-button>
                            </template>
                            <template v-if="$index === store.states.data.length - 1">
                                <el-button plain size="mini" @click="addRowExecutiveCompany">
                                    <i class="fa-solid fa-plus" />
                                </el-button>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {
        RadioButtonList: () => import("../../../../components/content/RadioButtonList.vue"),
    },
    props: {
        executivePerson: {
            type: Array,
        },
        executiveCompany: {
            type: Array,
        },
        selectExecutiveRole: {
            type: Array,
        },
        yesNoExecutive: {
            type: Array,
        },
        yesNoExecutiveCompany: {
            type: Array,
        },
    },
    methods: {
        addRowExecutivePerson() {
            const newObj = {
                firstName: "",
                lastName: "",
                socialSecurityNumber: "",
                role: "",
                owner: 0,
                numAShares: "",
                numBShares: "",
                numVotes: "",
            };
            this.executivePerson.push(newObj);
        },
        deleteRowExecutivePerson(row) {
            if (this.executivePerson.length > 0) {
                const index = this.executivePerson.indexOf(row);
                if (index > -1) {
                    this.executivePerson.splice(index, 1);
                }
            }
        },
        addRowExecutiveCompany() {
            const newObj = {
                companyName: "",
                representative: "",
                identificationNumber: "",
                role: "",
                owner: 0,
                numAShares: "",
                numBShares: "",
                numVotes: "",
            };
            this.executiveCompany.push(newObj);
        },
        deleteRowExecutiveCompany(row) {
            if (this.executiveCompany.length > 0) {
                const index = this.executiveCompany.indexOf(row);
                if (index > -1) {
                    this.executiveCompany.splice(index, 1);
                }
            }
        },
    },
};
</script>
